import HeroHome from "../common/hero/HeroHome";

const Home = () => {
  return (
    <>
      <HeroHome />
    </>
  );
};

export default Home;
